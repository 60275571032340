<template>
  <div class="dm-landing-page">
    <b-container fluid>
      <b-row class="no-gutter vh-100">
        <b-col cols="12" xl="7" order-xl="2">
          <div
            class="d-flex justify-content-center align-items-center flex-column p-4 h-100"
          >
            <h2 class="text-primary font-weight-semibold mt-5 mb-4 text-center">
              Your Money, Credit, and Privacy in One Place
            </h2>
            <div class="form-container max-width-480">
              <p class="font-weight-semibold text-center">
                Create your free 14-day trial account and get access to
                exclusive, interactive credit tools that help raise and maintain
                your credit score directly through our platform.
              </p>
              <form autocomplete="off">
                <b-row>
                  <b-col cols="12" lg="6">
                    <label for="firstName" class="invisible m-0"
                      >First Name</label
                    >
                    <b-form-input
                      required
                      type="text"
                      name="firstName"
                      v-model.trim.lazy="$v.form.firstName.$model"
                      placeholder="First Name"
                      :class="{
                        'form-group--error': $v.form.firstName.$error,
                      }"
                    />
                    <small class="mt-2 form-group--error">{{
                      validationMsg($v.form.firstName)
                    }}</small>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <label for="lastName" class="invisible m-0"
                      >Last Name</label
                    >
                    <b-form-input
                      required
                      type="text"
                      name="lastName"
                      v-model.trim.lazy="$v.form.lastName.$model"
                      placeholder="Last Name"
                      :class="{
                        'form-group--error': $v.form.lastName.$error,
                      }"
                    />
                    <small class="mt-2 form-group--error">{{
                      validationMsg($v.form.lastName)
                    }}</small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <label for="email" class="invisible m-0">Email</label>
                    <b-form-input
                      required
                      type="email"
                      name="email"
                      v-model.trim.lazy="$v.form.email.$model"
                      placeholder="Email"
                      :class="{
                        'form-group--error': $v.form.email.$error,
                      }"
                    />
                    <small class="mt-2 form-group--error">{{
                      validationMsg($v.form.email)
                    }}</small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <button
                      @click="submitForm"
                      class="dm-btn__primary mt-4 mb-4 mx-auto"
                    >
                      Sign Up Now!
                    </button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <small class="text-primary font-weight-bold"
                      >Free 14-Day Trial then $19.95/month. Cancel
                      anytime.</small
                    >
                  </b-col>
                </b-row>
              </form>
            </div>
            <img
              class="d-block mb-4"
              src="@/assets/images/BBB-logo.png"
              alt="BBB logo"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          xl="5"
          order-xl="1"
          class="bg-primary d-flex flex-column justify-content-center align-items-center text-white p-4"
        >
          <div class="dotlottie-container">
            <dotlottie-player src="/lotties/coworkers.lottie" autoplay loop />
          </div>
          <div class="feature-container">
            <h3 class="my-4 font-weight-bold">Our Platform Helps You</h3>
            <FeatureBullet feature="See Your Future Credit Score" />
            <FeatureBullet feature="Get a Personalized Credit-Building Plan" />
            <FeatureBullet
              feature="Directly Dispute Negative Credit Report Items"
            />
            <FeatureBullet
              feature="Stop Data Brokers From Selling Your Information"
            />
            <FeatureBullet
              feature="Insure Your Entire Family with $1MM ID Theft Insurance"
            />
            <button
              @click="lightBoxToggle"
              class="dm-btn__secondary mt-5 mx-auto"
            >
              &#9658; View a Demo
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import FeatureBullet from '@/components/common/FeatureBullet'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import { validationMessage } from 'vuelidate-messages'

const formMessages = {
  required: () => 'This field is required',
  email: () => 'Please enter a valid email',
  maxLength: () => 'Please enter a valid email',
  phone: () => 'Please enter a valid phone',
}

export default {
  name: 'DigitalMarketingLandingPage',
  components: {
    FeatureBullet,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
      },
      submitError: '',
      intialUrlParameters: [],
      trialUrl: 'https://member.scoremaster.com/join/?adid=email_landingpage',
    }
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
        maxLength: maxLength(254),
      },
    },
  },
  mounted() {
    this.setInitialParam()
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  computed: {
    signupUrl: function() {
      // constructs url to take user upon form submission

      let url = this.trialUrl //initial sign up url

      if (this.intialUrlParameters.length) {
        //pass url parameters to new url

        this.intialUrlParameters.forEach(param => {
          url = url + '&' + param[0] + '=' + param[1]
        })
      }
      return (
        //add form fields data to url parameters

        url +
        '&firstName=' +
        this.form.firstName +
        '&lastName=' +
        this.form.lastName +
        '&email=' +
        this.form.email +
        '&tracking=' +
        this.form.email
      )
    },
  },
  methods: {
    setInitialParam() {
      let paramArr = []
      const params = new URLSearchParams(encodeURI(window.location.search))
      const entries = params.entries()

      for (const entry of entries) paramArr.push(entry)

      this.intialUrlParameters = [...paramArr]
    },
    submitForm(e) {
      e.preventDefault()

      if (this.$v.form.$invalid) {
        //if form fields are not valid, display error message on each field

        this.$v.form.firstName.$touch()
        this.$v.form.lastName.$touch()
        this.$v.form.email.$touch()
      } else {
        //if form fields are valid, go to signup page with url parameters

        window.location = this.signupUrl
      }
    },
    lightBoxToggle(e) {
      e.preventDefault()
      Fancybox.show([
        {
          src:
            'https://player.vimeo.com/video/693718297?h=7fe1e10a4f&autoplay=1',
          type: 'iframe',
        },
      ])
    },
    validationMsg: validationMessage(formMessages),
  },
}
</script>

<style lang="scss">
footer {
  display: none;
}

input:not([type='checkbox']):not([type='radio']) {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  border: none;
  padding: 0 20px;
  border-color: transparent;
}
</style>
<style lang="scss" scoped>
.dm-landing-page {
  .no-gutter.vh-100 {
    height: calc(100vh - 70px) !important;
  }
}

.max-width-480 {
  max-width: 480px;
}

.dm-btn {
  display: block;
  width: 100%;
  border-color: transparent;
  background-color: transparent;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 45px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &__primary {
    @extend .dm-btn;
    background-color: $info;
    border: 2px solid $info;
    color: $white;
    &:hover {
      background-color: rgba($info, 0.6);
    }
  }
  &__secondary {
    @extend .dm-btn;
    border: 2px solid $white;
    color: $white;
    &:hover {
      color: $primary;
      background-color: $white;
    }
  }
}

.dotlottie-container {
  max-width: 500px;
}

::placeholder {
  color: $black;
}
</style>
