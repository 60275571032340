<template>
  <div class="FeatureBullet d-flex align-items-center mb-3">
    <img
      class="mr-3"
      src="@/assets/images/feature-bullet.png"
      alt="list item bullet"
      aria-hidden="true"
    />
    <p class="font-weight-bold">
      {{ feature }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FeatureBullet',
  props: {
    feature: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  height: 18px;
}
p {
  margin: 0;
}
</style>
