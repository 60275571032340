<template>
  <div class="dm-landing-page">
    <b-container fluid>
      <b-row class="no-gutter vh-100">
        <b-col cols="12" xl="7" order-xl="2">
          <div
            class="d-flex justify-content-center align-items-center flex-column p-4 h-100"
          >
            <h2 class="text-primary font-weight-semibold mt-5 mb-4 text-center">
              Your Money, Credit, and Privacy in One Place
            </h2>
            <div class="form-container max-width-480">
              <p class="font-weight-semibold text-center">
                Create your free 14-day trial account and get access to
                exclusive, interactive credit tools that help raise and maintain
                your credit score directly through our platform.
              </p>
              <div
                id="cd-signup-widget"
                :data-clientkey="clientkey"
                data-memberurl="https://member.scoremaster.com"
                data-productname="Scoremaster"
                data-analytics="false"
                v-bind:data-sapi-endpoint="sapiEndpoint"
              ></div>
              <b-row>
                <b-col class="text-center">
                  <small class="text-primary font-weight-bold"
                    >Free 14-Day Trial then $19.95/month. Cancel anytime.</small
                  >
                </b-col>
              </b-row>
            </div>
            <img
              class="d-block mb-4"
              src="@/assets/images/BBB-logo.png"
              alt="BBB logo"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          xl="5"
          order-xl="1"
          class="bg-primary d-flex flex-column justify-content-center align-items-center text-white p-4"
        >
          <div class="dotlottie-container">
            <dotlottie-player src="/lotties/coworkers.lottie" autoplay loop />
          </div>
          <div class="feature-container">
            <h3 class="my-4 font-weight-bold">Our Platform Helps You</h3>
            <FeatureBullet feature="See Your Future Credit Score" />
            <FeatureBullet feature="Get a Personalized Credit-Building Plan" />
            <FeatureBullet
              feature="Directly Dispute Negative Credit Report Items"
            />
            <FeatureBullet
              feature="Stop Data Brokers From Selling Your Information"
            />
            <FeatureBullet
              feature="Insure Your Entire Family with $1MM ID Theft Insurance"
            />
            <button
              @click="lightBoxToggle"
              class="dm-btn__secondary mt-5 mx-auto"
            >
              &#9658; View a Demo
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FeatureBullet from '@/components/common/FeatureBullet'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

const signupStepNames = {
  AccountStep: {
    url: '/join/login-credentials',
    title: 'Join:login-credentials',
  },
  PersonalStep: {
    url: '/join/identity',
    title: 'Join:identity',
  },
  IdentityStep: {
    url: '/join/idma-loading',
    title: 'Join:idma-loading',
  },
  BillingStep: {
    url: '/join/credit-card',
    title: 'Join:credit-card',
  },
  ConfirmationStep: {
    url: '/join/welcome',
    title: 'Join:welcome',
  },
}

export default {
  name: 'DigitalMarketingLandingPage2',
  components: {
    FeatureBullet,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  data() {
    return {
      clientkey: process.env.VUE_APP_SM_API_KEY,
      sapiEndpoint:
        process.env.NODE_ENV === 'production'
          ? 'https://member.scoremaster.com/api/signup'
          : 'https://qa-sm.consumerdirect.com/api/signup',
    }
  },
  computed: {
    trackingData: function() {
      return this.$store.getters['member/getTrackingData']
    },
  },
  mounted() {
    if (!window.__PRERENDER_INJECTED) {
      this.initSignupWidget()
    }
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  methods: {
    initSignupWidget() {
      let signupScript = null
      signupScript = document.createElement('script')
      signupScript.async = true
      signupScript.defer = true
      signupScript.type = 'module'
      signupScript.src =
        process.env.NODE_ENV === 'production'
          ? 'https://cdn.consumerdirect.io/signup-widget/cd-signup-widget.js.gz'
          : 'https://dev-cdn.consumerdirect.io/signup-widget/cd-signup-widget.js.gz'

      document.body.appendChild(signupScript)

      if (process.env.VUE_APP_SEGMENT_ENABLED === '1') {
        document.addEventListener('cd-signup-next-step', this.nextStepEvent)
      }
    },
    nextStepEvent: function(cdEvent) {
      const step = signupStepNames[cdEvent.detail.step]
      window.analytics.page(step.url, {
        ...this.trackingData,
        widget: true,
        title: step.title,
      })
    },
    lightBoxToggle(e) {
      e.preventDefault()
      Fancybox.show([
        {
          src:
            'https://player.vimeo.com/video/693718297?h=7fe1e10a4f&autoplay=1',
          type: 'iframe',
        },
      ])
    },
  },
}
</script>

<style lang="scss">
#cd-signup-widget {
  --cd-body-background: #fff;
  --cd-text-color: #212529;
  --cd-link-color: #1f4dc5;
  --cd-primary-btn-bg: #57d3ff;
  --cd-primary-btn-hover: #57d3ff;
  --cd-btn-text-color: #fff;
  --cd-input-bg-normal: #fff;
  --cd-input-bg-active: #fff;
  --cd-input-text-color: #212529;
  --cd-error-color: #e33d4d;
  --cd-success-color: #4ec083;
  --cd-border-radius: 30px;
  --cd-border-color: #777;
  --cd-border-width: 0px;
  --cd-table-stripe: #e4e4e4;
  --cd-highlight-color: #8be9fd;
  --cd-font-size: 16px;
  --cd-font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  --cd-loading-color: hsl(217deg 66% 46%);
  --cd-optional-tag-bg: #e4e4e4;
  --cd-transition-duration: 0.3s;
  --cd-poor-strength-color: #e33d4d;
  --cd-weak-strength-color: #ffaa00;
  --cd-fair-strength-color: #96c848;
  --cd-good-strength-color: #009800;

  .cd-widget__container {
    margin: 2rem 0;

    .cd-form {
      fieldset .cd-form__legend {
        display: none;
      }
      &__input-group .cd-form__input-container {
        flex: 1;
      }

      &__email-domain-container .cd-form__input > p {
        margin-bottom: 0;
      }

      &__buttons-row {
        justify-content: center;
        margin-top: 1.5rem;

        button {
          border-radius: 45px;
          height: 50px;
          padding: 0.4rem 3.5rem;
        }
      }

      &__buttons-row--sponsorcode {
        font-size: 14px;
      }
    }
  }
}
footer {
  display: none;
}

input:not([type='checkbox']):not([type='radio']),
select {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-appearance: none;
  border: none;
  padding: 0 20px;
  border-color: transparent;
}
#cd-signup-widget .cd-widget__container input,
#cd-signup-widget .cd-widget__container select {
  padding: 0.4rem 20px;
}
</style>

<style lang="scss" scoped>
.dm-landing-page {
  .no-gutter.vh-100 {
    height: calc(100vh - 70px) !important;
  }
}

.max-width-480 {
  max-width: 480px;
}

.dm-btn {
  display: block;
  width: 100%;
  border-color: transparent;
  background-color: transparent;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 45px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &__primary {
    @extend .dm-btn;
    background-color: $info;
    border: 2px solid $info;
    color: $white;
    &:hover {
      background-color: rgba($info, 0.6);
    }
  }
  &__secondary {
    @extend .dm-btn;
    border: 2px solid $white;
    color: $white;
    &:hover {
      color: $primary;
      background-color: $white;
    }
  }
}

.dotlottie-container {
  max-width: 500px;
}

::placeholder {
  color: $black;
}
</style>
